<template>
  <div>
    <b-row class="mb-2">
      <b-col md="12" style="display: flex; gap:10px;">
        <export-button
          :filter="exportFilter"
          url="/transaction/recharge-retreat/export"
          :disabled="loadindData"
          file-title="Recharges_and_withdrawals_Report"
        />
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{
            $t("tabs.rechargeretirement")
          }}</b-card-title>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFrom"
                  :disabled="loadindData"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                  @on-close="getTotals"
                ></flat-pickr>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateTo"
                  :disabled="loadindData"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                  @on-close="getTotals"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
                <b-form-group
                  label="Currency"
                  label-for="currency"
                >
                  <template #label>{{$t('labels.currency')}}</template>
                  <v-select
                    v-model="currency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currenciesOptions"
                    :reduce="(option) => option.abbreviation"
                    @input="currencyChanged"
                    :clearable="false"
                    label="name"
                  />
                </b-form-group>
                </b-col> -->

            <b-col md="4">
              <b-form-group label="Type User" label-for="Type User">
                <template #label>{{ $t("labels.typeuser") }}</template>
                <v-select
                  v-model="typeUser"
                  :disabled="loadindData"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeUserOptions"
                  @input="getTotals"
                  :clearable="false"
                  label="label"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Search Username" label-for="username">
                <template #label>{{ $t("labels.search") }}</template>
                <b-form-input
                  id="username"
                  v-model="q"
                  @input="getTotalsLazy"
                  :disabled="loadindData"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group :label="$t('direct')" label-for="Directos">
                <b-form-checkbox
                  v-model="isDirect"
                  @input="getTotals"
                  :disabled="loadindData"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Superior" label-for="Superior">
                <b-form-checkbox
                  v-model="isSuperior"
                  @input="getTotals"
                  :disabled="loadindData"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('labels.search_historic')"
                label-for="isHistorical"
              >
                <b-form-checkbox
                  v-model="isHistorical"
                  @change="getTotals"
                  :disabled="loadindData"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('amount_greater_than')">
                <money
                  v-model="amount"
                  spellcheck="false"
                  :disabled="loadindData"
                  v-bind="{ prefix: currencySelectNavbar + ' ', masked: false }"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col md="12" class="d-flex justify-content-between w-100">
              <b-button variant="primary" @click="getTotals">{{
                $t("buttons.search")
              }}</b-button>

              <div v-if="dataTable.length > 0">
                <b-card-text class="mb-0 mr-1"> Details </b-card-text>
                <b-form-checkbox
                  v-model="detailsDataChecked"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </div>
            </b-col>
          </b-row> -->
          <br />
          <b-overlay :show="loadindData">
            <div>
              <div v-if="TransactionsRetiroRecharge.length > 0">
                <b-table
                  :items="TransactionsRetiroRecharge"
                  :fields="fields"
                  responsive
                >
                  <template #cell(dateCreat)="data">
                    {{ data.item.createdAt | moment }}
                  </template>
                  <template #cell(userOrigen)="data">
                    <feather-icon
                      :icon="getRoleIconAndVariant(data.item.user_from.typeUser).icon"
                      size="18"
                      class="mr-50"
                      :class="`text-${
                          getRoleIconAndVariant(data.item.user_from.typeUser).variant
                      }`"
                    />
                    <span>{{ data.item.user_from.userName }}</span>
                  </template>
                  <template #cell(userDestino)="data">
                    <feather-icon
                      :icon="getRoleIconAndVariant(data.item.user_to.typeUser).icon"
                      size="18"
                      class="mr-50"
                      :class="`text-${
                          getRoleIconAndVariant(data.item.user_to.typeUser).variant
                      }`"
                    />
                    <span>{{ data.item.user_to.userName}}</span>
                  </template>
                  <template #cell(amount)="data">
                    <span
                      v-if="data.item.operation == 'DEBIT'"
                      class="text-danger text-nowrap"
                      >{{ currencySelectNavbar }}
                      {{
                        ("-" + data.item.amount)
                          | currency({symbol: ""})
                      }}</span
                    >
                    <span v-else class="text-success text-nowrap"
                      >{{ currencySelectNavbar }}
                      {{
                      data.item.amount | currency({symbol: ""}) }}</span
                    >
                  </template>
                </b-table>
                <div class="w-full d-flex justify-content-end">
                  <h5 class="mr-2">
                    {{$t('total')}}: {{ currencySelectNavbar }}
                    {{ totalAmount | currency({symbol: ""})  }}
                  </h5>
                </div>

                <div class="w-full d-flex justify-content-between">
                  <v-select
                    v-model="limit"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="limitOptions"
                    @input="getTotalsLazy"
                    :clearable="false"
                  />
                  <div>
                    <b-pagination
                      v-model="page"
                      :total-rows="totalRows"
                      :per-page="limit"
                      :simple="true"
                      :hide-goto-end-buttons="true"
                    ></b-pagination>
                  </div>
                </div>
              </div>
              <div v-else>
                <h5 class="text-center">
                  {{ $t('no_data') }}
                </h5>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>
      <b-col md="3" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="selectUserHierarchy" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BPagination,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import userStoreModule from "../../apps/user/userStoreModule";
import clientStoreModule from "../clients/clientStoreModule";
import whitelabelStoreModule from "../whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import { axiosErrorHandle } from '@core/utils/errorHandler'
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";

const fechaActual = new Date();

export default {
  components: {
    ExportButton,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    VSelectTree,
    vSelect,
    VTree,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BPagination,
    flatPickr,
    HierarchyUsers,
  },
  data() {
    return {
      isDirect: false,
      isSuperior: false,
      totalAmount: 0,
      q: "",
      localTypeUser: { label: this.$t('buttons.all'), value: "All" },
      searchUser: "",
      dateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataGamesFullDetails: [],
      dataTable: [],
      rangeDate: "",
      idUserSelect: JSON.parse(localStorage.getItem("userData"))._id,
      detailsDataChecked: false,
      loadindData: false,
      TransactionsRetiroRecharge: [],
      isHistorical : false,
      timeOutGetTotals: null,
      page: 1,
      limit: 10,
      totalRows: 0,
      limitOptions: [10, 20, 50, 100, 200, 500],
      amount: 0,
      width: 0,
    };
  },
  watch: {
    detailsDataChecked(val) {
      if (val) this.dataTable = this.dataGamesFullDetails;
      else this.dataTable = this.dataGames;
    },

    isDirect(val) {
      //do something when the data changes.
      if (val === true) {
        this.isSuperior = false;
      }
    },

    isSuperior(val) {
      //do something when the data changes.
      if (val === true) {
        this.isDirect = false;
      }
    },
    // currencySelectNavbar() {
    //   this.getTotalsLazy();
    // },
    // whitelabelSelectNavbar() {
    //   this.getTotalsLazy();
    // },
    page(newPage) {
      this.getTotals(newPage);
    },

    amount(){
      this.getTotalsLazy()
    }
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(WL_APP_STORE_MODULE_NAME))
      store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
    const currencies = ref([]);
    const clientCurrencies = ref([]);

    const users = ref([]);
    const treeData = ref([]);
    const previosNode = ref("");
    const previousNodeFlag = ref(false);
    const selection = ref([]);
    const usersForReport = ref([]);
    const dataGames = ref([]);
    const game = ref([]);

    return {
      dataGames,
      game,
      treeData,
      users,
      usersForReport,
      currencies,
      clientCurrencies,
      selection,
      previousNodeFlag,
      previosNode,
    };
  },
  computed: {
    typeUser: {
      get() {
        return this.localTypeUser
      },
      set(newValue) {
        this.localTypeUser = newValue
      }
    },
    typeUserOptions(){
      return  [
        {label: this.$t('buttons.all'), value: "All"},
        {label: this.$t('tabs.agent'), value: "Agent"},
        {label: this.$t('tabs.player'), value: "Player"},
      ]
    },
    fields(){
     return  [
       { key: "dateCreat", label: this.$t('labels.date') },
       { key: "userOrigen", label: this.$t('source_user') },
       { key: "userDestino", label: this.$t('target_user') },
       { key: "amount", label: this.$t('labels.amount') },
     ]
    },
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
    exportFilter(){
      return {
        id: this.idUserSelect,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar?._id,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        getType: this.typeUser.value,
        q: this.q,
        typeOperation : this.isDirect ? 'directs' : (this.isSuperior ? 'superior' : ''),
        // isDirect: this.isDirect,
        // isSuperior: this.isSuperior,
        page: this.page,
        perPage: this.limit,
        isHistorical: this.isHistorical
      }
    }
  },
  methods: {
    yearDropdownPlugin,
    getRoleIconAndVariant(role){
      const return_object = {variant : '', icon : ''}
      if (role === 'Root'){
        return_object.variant = 'primary'
        return_object.icon = 'UserPlusIcon'
      }
      if (role === 'Player'){
        return_object.variant = 'danger'
        return_object.icon = 'PlayIcon'
      }
      if (role === 'Agente'){
        return_object.variant = 'success'
        return_object.icon = 'UsersIcon'
      }
      if (role === 'Admin'){
        return_object.variant = 'success'
        return_object.icon = 'UserIcon'
      }
      return return_object
    },
    currencyChanged() {
      this.getTotals();
    },
    async getTotals(page = null) {
      if (!this.whitelabelSelectNavbar)
        return

      if (!page)
        this.page = 1

      this.game = [];
      this.dataGames = [];
      this.dataGamesFullDetails = [];
      this.validateData();
      try {
        if (this.idUserSelect == "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert!",
              icon: "AlertTriangleIcon",
              text: this.$t('select_user_tree'),
              variant: "warning",
            },
          });
          return false;
        }
        this.loadindData = true;
        const payload = {
          id: this.idUserSelect,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          getType: this.typeUser.value,
          q: this.q,
          typeOperation : this.isDirect ? 'directs' : (this.isSuperior ? 'superior' : ''),
          // isDirect: this.isDirect,
          // isSuperior: this.isSuperior,
          page: this.page,
          perPage: this.limit,
          isHistorical: this.isHistorical,
          amount : this.amount
        };
        const { data } = await store.dispatch(
          "app-client/fetchRechargeRetirement",
          payload
        );

        this.TransactionsRetiroRecharge = data.data
        // this.TransactionsRetiroRecharge = data.data.sort((a, b) =>
        //   a.createdAt > b.createdAt ? 1 : -1
        // );

        this.totalAmount = data.totalAmount;

        this.totalRows = data.total;
      } catch (error) {
        this.resetData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text: axiosErrorHandle(error),
            variant: "danger",
          },
        });
      } finally {
        this.loadindData = false;
      }
    },

    getDetails() {
      this.game = [];
      this.dataGames = [];
      this.validateData();
      if (this.usersForReport.length > 0) {
        store
          .dispatch("app-client/fetchReportGateway", {
            currency: this.currencySelectNavbar,
            datein: this.dateFrom,
            dateout: this.dateTo,
            users: this.usersForReport,
          })
          .then((response) => {
            this.game = response;
          })
          .then(() => {
            var gameAux = {
              Category: this.game.Name,
              Bets: this.game.Bets,
              Betted: this.game.Betted,
              Won: this.game.Won,
              NetWin: this.game.NetWin,
              _rowVariant: "success",
            };

            this.dataGames.push(gameAux);

            const categories = this.game.Categories.length;
            for (var i = 0; i < categories; i++) {
              var gameAux = {
                Category: this.game.Categories[i].Name,
                Bets: this.game.Categories[i].Bets,
                Betted: this.game.Categories[i].Betted,
                Won: this.game.Categories[i].Won,
                NetWin: this.game.Categories[i].NetWin,
                _rowVariant: "info",
              };

              this.dataGames.push(gameAux);

              const products = this.game.Categories[i].Products.length;
              for (var j = 0; j < products; j++) {
                var gameAux = {
                  Category: this.game.Categories[i].Products[j].Name,
                  Bets: this.game.Categories[i].Products[j].Bets,
                  Betted: this.game.Categories[i].Products[j].Betted,
                  Won: this.game.Categories[i].Products[j].Won,
                  NetWin: this.game.Categories[i].Products[j].NetWin,
                };
                this.dataGames.push(gameAux);
              }
            }
          })
          .catch((error) => {
            this.resetData()
            console.log("error gateway: ", error);
          });
      }
    },
    resetData(){
      this.TransactionsRetiroRecharge = []
      this.dataGames = []
    },
    validateData() {
      if (this.dateTo < this.dateFrom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: this.$t('review_dates'),
            variant: "danger",
          },
        });
        return false;
      }
      if (this.dateFrom && this.dateFrom) {
        this.usersForReport = [];
        this.selection.forEach((node) => {
          const user = this.users.find((element) => element.userName == node);
          if (user) {
            this.usersForReport.push(user.details.walletUserId);
          }
        });
      }
    },
    selectUserHierarchy({ id }) {
      this.idUserSelect = id;
      this.getTotals();
    },
    getTotalsLazy() {
      if (this.timeOutGetTotals) {
        clearTimeout(this.timeOutGetTotals);
      }
      this.timeOutGetTotals = setTimeout(() => {
        this.getTotals();
      }, 800);
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
