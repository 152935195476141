<template>
  <div>
    <div v-if="formats.length > 1">
      <b-dropdown id="dropdown-1" variant="primary" :disabled="this.disabled" :text="$t('labels.export')">
        <b-dropdown-item @click="downloadFile(format)" :key="index" v-for="(format, index) in formats">
          <span>{{ format.toUpperCase() }}</span>
          <feather-icon icon="DownloadIcon" size="16" class="float-right"/>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-button
        v-else
        variant="primary"
        class="d-flex align-items-center justify-content-center"
        @click="downloadFile(formats[0])"
        :disabled="this.disabled"
    >
      <b-spinner v-if="buttonLoading" small class="mr-1"></b-spinner>
      <feather-icon v-else icon="DownloadIcon" size="16" class="mr-50"/>
      <span class="text-nowrap">{{ $t("labels.export") }}</span>
    </b-button>
  </div>
</template>

<script>
import {downloadReportFile} from "@core/utils/utils";
import {BButton, BNavItem} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  name: "ExportButton",
  components: {BNavItem, BButton},
  props: {
    filter: {},
    url: {},
    fileTitle: {},
    disabled: {},
    formats: {
      type: Array,
      default: () => ['xlsx']
    }
  },
  data() {
    return {
      buttonLoading: false,
    };
  },
  methods: {
    async downloadFile(format = 'xlsx') {
      try {
        this.buttonLoading = true;
        await downloadReportFile({
          url: this.url,
          filter: {...this.filter, format},
          fileTitle: `${this.fileTitle}.${format}`,
        });
        this.notify(this.$t('downloaded', {file: `${this.fileTitle}.${format}`}), "success");
      } catch (e) {
        console.log(e)
        this.notify(axiosErrorHandle(e), "danger");
      } finally {
        this.buttonLoading = false;
      }
    },

    notify(title, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title),
          icon: "DownloadIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
